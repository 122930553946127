import { useCallback } from 'react'
import { usePropertyOverviewUrl } from './usePropertyOverviewUrl'

export const useGetPropsUrlWithRegionQueryParam = () => {
  const propsUrl = usePropertyOverviewUrl()

  return useCallback(
    (region: string) => {
      return `${propsUrl}?regions=${region}`
    },
    [propsUrl]
  )
}
