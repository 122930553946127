import { useAnimationOnScreen } from '@dreamstack/util'
import { motion } from 'framer-motion'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import type { OriginX } from './AnimateScaleOnHover'

const StyledReveal = tw(motion.div)`absolute inset-0 bg-white z-10`

export const AnimateReveal: FunctionComponent<React.PropsWithChildren<{
  originX: OriginX
}>> = ({ children, originX }) => {
  const { controls, ref } = useAnimationOnScreen()

  return (
    <StyledReveal
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.3 }}
      variants={{
        visible: { scaleX: 0, scaleY: 1, originX: originX },
        hidden: { scaleX: 1, scaleY: 1, originX: originX },
      }}
    ></StyledReveal>
  )
}
