import type { OriginX, OriginY } from '@dreamstack/feature-components'
import {
  AnimateReveal,
  AnimateScaleOnHover,
  Css_SmallText,
  Css_Text_28_36_To_16_24,
  GridWithGaps,
  useGetPropsUrlWithRegionQueryParam,
  useIsMlOrGreater,
  usePropertyOverviewUrl,
} from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'
import { ArrowRight } from '@dreamstack/icons'
import map from 'lodash/map'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import tw, { styled } from 'twin.macro'
import TailwindConfig from '../../../../../tailwind.config'
import { BigTitleNavigation } from '../BigTitleNavigation'

const bgAccentroGrayFull = TailwindConfig.theme.extend.colors.accentroGray.full
const bgAccentroAquaFull = TailwindConfig.theme.extend.colors.accentroAqua.full

const Container = tw.div`col-span-12 flex flex-col space-y-px-40 md:(space-y-px-48) ml:(flex-row-reverse space-y-0 items-center justify-around)`
const RegionContainer = tw.div`justify-center flex flex-1 items-center space-x-px-8 md:(space-x-px-12 ) ml:(flex-initial mr-px-64)`
const RegionContainerLeft = tw.div`flex flex-col items-end content-center space-y-px-8 md:(space-y-px-12)`
const RegionContainerRight = tw.div`flex flex-col items-start content-center space-y-px-8 md:(space-y-px-12)`
// const TextContainer = tw.div`flex-1 items-center mb-px-24 ml:(mb-px-64) lg:(mb-0)`

const RegionBox = tw.div`p-px-12 md:(p-px-20) w-min text-accentroWhite-full cursor-pointer`
const RegionHeadingText = styled.h3(() => [
  Css_Text_28_36_To_16_24,
  tw`font-semibold pb-px-24 pr-px-32 md:(pb-px-48 pr-px-96)`,
])

const RegionObjectCount = styled.span(() => [
  Css_SmallText,
  tw`flex items-center justify-end whitespace-nowrap`,
])
const RegionBoxCountArrow = tw.span`text-right`

const ALL_REGIONS = {
  left: ['Berlin', 'Nordrhein-Westfalen'],
  right: ['Mecklenburg-Vorpommern', 'Sachsen'],
}

const RegionBoxTemplate = ({
  region,
  href,
  index,
  column,
}: {
  region: any
  href: any
  index: any
  column: any
}) => {
  // const t = useTranslation()
  const { originX, originY } = getTransformOriginByIndex(index, column)
  return (
    <Link
      href={href}
      className="ga-region-quick-selector"
      tw="h-full inline-block relative"
      data-ga-element-id="region-quick-selector"
    >
      <AnimateReveal originX="right" />
      <AnimateScaleOnHover
        key={region}
        originX={originX}
        originY={originY}
        backgroundColorFrom={bgAccentroGrayFull}
        backgroundColorTo={bgAccentroAquaFull}
      >
        <RegionBox>
          <RegionHeadingText>{region}</RegionHeadingText>
          <RegionObjectCount>
            {/* {count} {t('accentro:regionQuickSelector.object', { count })} */}
            <RegionBoxCountArrow>
              <ArrowRight tw="w-px-32 h-px-32" />
            </RegionBoxCountArrow>
          </RegionObjectCount>
        </RegionBox>
      </AnimateScaleOnHover>
    </Link>
  )
}

const getPositionForRegionGrid = (index: number) => {
  const isFirst = index === 0
  const isLast = index === ALL_REGIONS.left.length - 1
  return { isFirst, isLast }
}

const getTransformOriginByIndex = (
  index: number,
  column: 'left' | 'right'
): { originX: OriginX; originY: OriginY } => {
  const { isFirst, isLast } = getPositionForRegionGrid(index)
  const originY: OriginY = isFirst ? 'bottom' : isLast ? 'top' : 'center'
  if (column === 'left') {
    return { originX: 'right', originY: originY }
  } else {
    return { originX: 'left', originY: originY }
  }
}

export const RegionQuickSelector: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  const t = useTranslation()
  const getPropsUrlWithRegionQueryParam = useGetPropsUrlWithRegionQueryParam()
  const propsUrl = usePropertyOverviewUrl()

  const isMl = useIsMlOrGreater()

  // const { onHoverStart, controls } = useAnimateIconTranslationRight()

  return (
    <GridWithGaps>
      <Container>
        <BigTitleNavigation
          block={{
            title: t('accentro:regionQuickSelector.whereAreYouSearching'),
            subTitle: t('accentro:regionQuickSelector.selectCityOrRegion'),
            buttonText: t('accentro:regionQuickSelector.showAll'),
            staticPageReference: { slug: propsUrl },
          }}
          displayAsColumn={isMl}
        />
        <RegionContainer>
          <RegionContainerLeft>
            {map(ALL_REGIONS.left, (region, index) => {
              // const count = countByRegion.find((r) => r.region === region).count
              return (
                <RegionBoxTemplate
                  key={index}
                  column="left"
                  index={index}
                  region={region}
                  href={getPropsUrlWithRegionQueryParam(region)}
                  // count={count}
                />
              )
            })}
          </RegionContainerLeft>
          <RegionContainerRight>
            {map(ALL_REGIONS.right, (region, index) => {
              // const count = countByRegion.find((r) => r.region === region).count
              return (
                <RegionBoxTemplate
                  column="right"
                  index={index}
                  key={region}
                  region={region}
                  href={getPropsUrlWithRegionQueryParam(region)}
                  // count={count}
                />
              )
            })}
          </RegionContainerRight>
        </RegionContainer>
      </Container>
    </GridWithGaps>
  )
}
