import { useAnimationOnScreen } from '@dreamstack/util'
import { motion } from 'framer-motion'
import type { FunctionComponent } from 'react'

export type OriginX = 'left' | 'center' | 'right'
export type OriginY = 'top' | 'center' | 'bottom'

export const AnimateScaleOnHover: FunctionComponent<React.PropsWithChildren<{
  originX: OriginX
  originY: OriginY
  backgroundColorFrom?: string
  backgroundColorTo?: string
}>> = ({
  children,
  originX,
  originY,
  backgroundColorFrom,
  backgroundColorTo,
}) => {
  const { controls, ref } = useAnimationOnScreen()

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="visible"
      whileHover="onHover"
      transition={{ ease: 'easeOut', duration: 0.3 }}
      variants={{
        visible: { scale: 1, backgroundColor: backgroundColorFrom },
        onHover: {
          scale: 1.15,
          originX: originX,
          originY: originY,
          backgroundColor: backgroundColorTo,
        },
      }}
    >
      {children}
    </motion.div>
  )
}
